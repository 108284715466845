import localFont from 'next/font/local';

const primary = localFont({
  src: [
    {
      path: './fonts/montserrat-regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/montserrat-italic.woff',
      weight: '400',
      style: 'italic',
    },
    {
      path: './fonts/montserrat-semibold.woff',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/montserrat-bold.woff',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/montserrat-extrabold.woff',
      weight: '800',
      style: 'normal',
    },
  ],
  variable: '--font-primary',
});

export const fonts = {
  primary,
};
